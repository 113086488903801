import { Action, Reducer } from 'redux';

import { IAppThunkAction, IAnalyticsAction } from '.';
import Player from '../interfaces/IPlayer';
import { IUpdateBookingSuccessAction, IUpdateBookingRequestedAction } from './Booking';
import { GoogleTagManagerEvents } from '../helpers/googleTagManager';

import PlayerService from '../service/players'
export interface IPlayerState {
    selectedPrivateEventTypeId?: number;
    selectedPlayers?: number;
    selectedPlayersUI: boolean;
    selectedPrivateUpgrade: boolean;
    knownPlayer?: Player;
    knownPlayerLoading: boolean;
}

export const PLAYERS_CLEAR: 'PLAYERS_CLEAR' = 'PLAYERS_CLEAR';
export const PLAYERS_SELECTED: 'PLAYERS_SELECTED' = 'PLAYERS_SELECTED';
export const PRIVATE_EVENT_TYPE_SELECTED: 'PRIVATE_EVENT_TYPE_SELECTED' = 'PRIVATE_EVENT_TYPE_SELECTED';
export const PLAYER_SEARCH_REQUESTED: 'PLAYER_SEARCH_REQUESTED' = 'PLAYER_SEARCH_REQUESTED';
export const PLAYER_SEARCH_RECEIVED: 'PLAYER_SEARCH_RECEIVED' = 'PLAYER_SEARCH_RECEIVED';
export const PLAYER_SEARCH_FAILED: 'PLAYER_SEARCH_FAILED' = 'PLAYER_SEARCH_FAILED';
export const PLAYER_PRIVATE_UPGRADE_SELECTED: 'PLAYER_PRIVATE_UPGRADE_SELECTED' = 'PLAYER_PRIVATE_UPGRADE_SELECTED';

export interface IPrivateEventTypeSelectedAction extends IAnalyticsAction {
    type: typeof PRIVATE_EVENT_TYPE_SELECTED;
    privateEventType: number;
}

export interface IPlayersSelectedAction extends IAnalyticsAction {
    type: typeof PLAYERS_SELECTED;
    players: number;
}

interface IKnownPlayerSearchRequested extends Action {
    type: typeof PLAYER_SEARCH_REQUESTED;
}
interface IKnownPlayerSearchReceived extends IAnalyticsAction {
    type: typeof PLAYER_SEARCH_RECEIVED;
    player: Player | undefined;
}
interface IKnownPlayerSearchFailed extends Action {
    type: typeof PLAYER_SEARCH_FAILED;
}
interface IPlayersClear extends Action {
    type: typeof PLAYERS_CLEAR;
}
interface IPrivateUpgradeSelected extends IAnalyticsAction {
    type: typeof PLAYER_PRIVATE_UPGRADE_SELECTED;
    upgradeChecked: boolean;
}

export type KnownPlayerAction =
    | IPlayersSelectedAction
    | IKnownPlayerSearchFailed
    | IKnownPlayerSearchReceived
    | IKnownPlayerSearchRequested
    | IUpdateBookingSuccessAction
    | IUpdateBookingRequestedAction
    | IPlayersClear
    | IPrivateEventTypeSelectedAction
    | IPrivateUpgradeSelected;

export const actionCreators = {
    clearPlayers: () => ({ type: PLAYERS_CLEAR } as IPlayersClear),

    selectPlayers: (players: number) =>
        ({
            type: PLAYERS_SELECTED,
            players: players,
            googleTagManagerEvent: GoogleTagManagerEvents.PlayersSelected(players),
        } as IPlayersSelectedAction),

    lookupPlayer: (emailAddress: string): IAppThunkAction<KnownPlayerAction> => (dispatch, getState) => {
        dispatch({
            type: PLAYER_SEARCH_RECEIVED,
            player: undefined,
            googleTagManagerEvent: GoogleTagManagerEvents.PlayerSearchReceived(false),
        });
    },
    
    selectPrivateEventType: (privateEventType: number): IAppThunkAction<KnownPlayerAction> => (dispatch, getState) => {
        dispatch({
            type: PRIVATE_EVENT_TYPE_SELECTED,
            privateEventType: privateEventType,
            googleTagManagerEvent: GoogleTagManagerEvents.PrivateEventTypeSelected(privateEventType),
        });
    },
    
    selectPrivateUpgrade: (upgradeChecked: boolean): IAppThunkAction<KnownPlayerAction> => (dispatch, getState) => {
        dispatch({
            type: PLAYER_PRIVATE_UPGRADE_SELECTED,
            upgradeChecked: upgradeChecked,
            googleTagManagerEvent: GoogleTagManagerEvents.PrivateUpgradeSelected(upgradeChecked)
        });
    },

    validateEmail: (emailAddress: string) => {
        if (emailAddress != null)
            return PlayerService.ValidateEmail(emailAddress);
    }
};

export const reducer: Reducer<IPlayerState> = (
    state: IPlayerState | undefined,
    incomingAction: Action
): IPlayerState => {
    if (state === undefined)
        return {
            selectedPlayers: undefined,
            selectedPlayersUI: false,
            knownPlayer: undefined,
            knownPlayerLoading: false,
            selectedPrivateUpgrade: false
        };

    const action: KnownPlayerAction = incomingAction as KnownPlayerAction;
    switch (action.type) {
        case PLAYERS_CLEAR:
            return {
                ...state,
                selectedPlayers: undefined,
                selectedPlayersUI: false,
                knownPlayerLoading: false,
                knownPlayer: undefined,
            };
        case PLAYERS_SELECTED:
            return {
                ...state,
                selectedPlayers: action.players,
                selectedPlayersUI: true,
                knownPlayerLoading: false,
            };
        case PRIVATE_EVENT_TYPE_SELECTED:
            return {
                ...state,
                selectedPrivateEventTypeId: action.privateEventType,
            };
        case PLAYER_SEARCH_FAILED:
            return {
                ...state,
                knownPlayer: undefined,
                knownPlayerLoading: false,
            };
        case PLAYER_SEARCH_RECEIVED: {
            return {
                ...state,
                knownPlayer: action.player,
                knownPlayerLoading: false,
            };
        }
        case PLAYER_SEARCH_REQUESTED: {
            return {
                ...state,
                knownPlayer: undefined,
                knownPlayerLoading: true,
            };
        }
        case PLAYER_PRIVATE_UPGRADE_SELECTED: {
            return {
                ...state,
                selectedPrivateUpgrade: action.upgradeChecked
            };
        }
        default:
            return state;
    }
};
